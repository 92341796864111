<template>
    <list-condition-template ref="table" :rows="rows" :total="total"
     @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"
      v-model:listQuery="listQuery" :index-column="true">
      <template #condition>
        <el-form-item>
          <div class="demo-date-picker">
            <div class="block">
              <span class="demonstration">筛选日期：</span>
              <el-date-picker
                clearable
                value-format="YYYY-MM-DD"
                v-model="valueDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-select @change="handleFilter" clearable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="订单状态" v-model="listQuery.condition.orderStatus">
            <el-option v-for="item in statusOptions" :key="item.name" :label="item.type" :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="handleFilter" placeholder="输入关键字信息查询" v-model="listQuery.condition.keyword" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
    </template>
    <template #columns>
        <el-table-column min-width="150px" label="采购单状态" align="center">
           <template #default="scope">
            <el-tag :type="showFilter(scope.row)" style="width: 90px">{{scope.row.orderStatus?scope.row.orderStatus.type:''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="订单批次" property="orderBatch" align="center">
          <template #default="scope">
            <span>{{scope.row.orderBatch}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="单据类型" align="center">标签采购单</el-table-column>
        <el-table-column min-width="150px" label="制单时间" property="orderTime" align="center">
          <template  #default="scope">
            <span>{{scope.row.orderTime}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100px" label="制单人账号" property="createBy" align="center">
          <template #default="scope">
            <span>{{scope.row.createBy}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="80px" label="防伪码标签（套）" property="securityNumber" align="center">
          <template #default="scope">
            <span>{{scope.row.securityNumber}}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150px" label="标签供应商" property="supplier.name" align="center">
          <template #default="scope">
            <span>{{scope.row.supplier.name}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200px" class-name="fixed-width">
          <template  #default="scope">
            <el-button type="primary" size="mini" @click="handleShow(scope.row)" v-waves>查看</el-button>
            <el-button v-if="scope.row.orderStatus.name ==='SHIPPED'" size="mini" type="success" @click="handleReceipt(scope.row)" v-waves>确认收货</el-button>
            <el-button size="mini" type="danger" v-if="scope.row.orderStatus && scope.row.orderStatus.name === 'PLACE_ORDER'" @click="onClickStopButton(scope.row)" v-waves>终止流程</el-button>
          </template>
        </el-table-column>
    </template>
    <el-dialog v-model="terminationShow" title="终止流程">
      <el-form ref="termination" :model="termination">
          <el-form-item label="终止原因:" prop="deleteReason" :rules="[{required: true, message: '终止原因不能为空',trigger:'blur'}]">
              <el-input type="textarea" rows="2" v-model="termination.deleteReason"></el-input>
          </el-form-item>
          <el-form-item>
              <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
              <el-button size="mini" type="primary" @click="onSubmitButtonClick(false)" :loading="termination.loading">{{termination.loading ? '提交中' : '确定'}}</el-button>
          </el-form-item>
      </el-form>
    </el-dialog>
  </list-condition-template>
</template>

<script>
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import myBillsOfOrderApi from '@/apis/order/myBillsOfOrder'
export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      statusOptions: [
        {
          name: 'PLACE_ORDER',
          type: '已下单'
        },
        {
          name: 'RECEIVE',
          type: '生产中'
        },
        {
          name: 'SHIPPED',
          type: '完成并发货'
        },
        {
          name: 'RECEIPT',
          type: '已收货'
        },
        {
          name: 'CANCEL',
          type: '已取消'
        }
      ],
      valueDate: undefined,
      rows: null,
      total: 0,
      listQuery: {
        condition:{
          keyword: null,
          enabled: null,
          startTime: undefined,
          endTime: undefined,
          orderStatus: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      task: undefined,
      taskId: undefined,
      params:{
        businessKey: undefined,
        localVariables: {
            approved: undefined,
            note: undefined
        }
      },
      workflowNode: {
        businessObject: {
        }
      },
      queryInfo: {
        id: undefined
      },
      terminationShow: false,
      termination:{
        loading:false,
        deleteReason:null,
        id:null,
        processInstanceId:null,
        processDefinitionKey: null
      }
    }
  },
  filters: {
  },
  mounted () {
    // if(this.$route.query.id){
    //   this.queryInfo.id = this.$route.query.id
    //   this.handleReceipt(this.queryInfo)
    // }
  },
  methods: {
    onSubmitButtonClick(){
      this.$refs.termination.validate().then(response => {
          if(response){
              this.$confirm('终止后无法恢复,确定要终止该流程吗？', '警告', {
                  type: 'warning'
              }).then(() => {
                  myBillsOfOrderApi.deleteWorkflow(this.termination).then(res => {
                      if(res.data){
                          this.$message.success('终止流程成功')
                          this.terminationShow = false
                          this.$refs.termination.resetFields()
                          this.getList()
                      }
                  })
              })
          }
      })
    },
    onCancelButtonClick(){
      this.terminationShow = false
      this.$refs.termination.resetFields()
    },
    onClickStopButton(row){
      this.terminationShow = true
      this.termination.id = row.id
      this.termination.processInstanceId = row.processInstanceId,
      this.termination.processDefinitionKey = 'CreateOrder'
    },
    showFilter(row){
      if(row.orderStatus && row.orderStatus.name === 'RECEIPT'){
        return 'success'
      }else if(row.orderStatus && row.orderStatus.name === 'SHIPPED'){
        return 'warning'
      }else if(row.orderStatus && row.orderStatus.name === 'RECEIVE'){
        return 'info'
      }else if(row.orderStatus && row.orderStatus.name === 'CANCEL'){
        return 'danger'
      }else{
        return ' '
      }
      
    },
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.startTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.endTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.startTime = undefined
        this.listQuery.condition.endTime = undefined
      }
      this.getList()
    },
    handlePageInfoChange () {
      this.getList()
    },
    getList () {
      myBillsOfOrderApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handleShow(row){
      //跳转到查看页面
      this.$router.push({path:`/business/cooperation/purchase/billsDetails/${row.id}`, query: { source:'myBills'}})
    },
    handleReceipt(row){
      myBillsOfOrderApi.getTasks({
          assignee: this.$store.state.user.account,
          processDefinitionKeys: [row.processDefinitionKey],
          businessKey: row.id
        }).then(resp=>{
          var task = resp.data[0]
          if (task) {
              this.task = task
              this.taskId = task.id
              this.params.localVariables.approved = true
              this.params.localVariables.note = '通过'
              this.params.businessKey = row.id
              myBillsOfOrderApi.audit({taskId:this.taskId,...this.params}).then(resp=>{
                if (resp.data) {
                  this.$message.success("确认收货成功")
                  this.getList()
                }
              })
          }else{
            this.$message.warning("无权操作")
            this.task = null
            this.taskId = null
          }
      })
    }
  }
}
</script>
