import api from '../api'
/**
 * 标签采购-我的单据
 */
export class MyBillsOfOrderApi extends api {
  audit(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/orderWorkflow/audit`,
      method: "post",
      data
    })
  }
  getTasks(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/getTasks`,
      method: "post",
      data
    })
  }
  /**
   * 删除工作流
   * @param {*} data 
   */
   deleteWorkflow(data){
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/workflow/delInstance`,
      method: "post",
      data
    })
  }
}

export default new MyBillsOfOrderApi('myBillsOfOrder')